import React from 'react';
import Post from '../components/post';
import EvenFinancialWidget from '../components/EvenFinancialWidget';
import LazyLoadImage from '../components/LazyLoadImage';


const StudentLoansPage = () => {
    const article = {
        id: '58e57424-5e2c-5e08-bd7a-c8ed6bf22049',
        title: '9 Effective Ways to Manage Your Student Loan Debt',
        slug: '/student-loans/',
        date: '2018-01-24T18:47:50.000Z',
        modified: '2021-09-20T20:41:03.000Z',
        excerpt: 'Student loans can create debt that carries on for years after graduation &#8211; one that can&#8217;t even be wiped out by declaring bankruptcy. Here are some helpful tips to manage and pay down this debt so that it doesn&#8217;t loom forever.',
        featured_image: {
            source_url: '/media/student-loan-debt-0.png',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 9,
        content: (
            <>
                <p>
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=student-loans&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">Student loans</a>
                    {' '}
                    are the bane of every college student, the looming spectre waiting in the wings on the day of graduation.
                </p>
                <p>
                    Countless memes and jokes exist solely on the reality of
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=student-loans&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                    {' '}
                    debt, and countless pages of advice have been written to address this problem.
                    {' '}
                </p>
                <p>
                    <a target="_blank" href="https://ticas.org/posd/state-state-data-2015" rel="noreferrer">According to the Institute for College Access &amp; Success</a>
                    , the average student graduating in 2014 left school with a debt of $28,950.
                </p>
                <p>
                    &quot;
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=student-loans&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">Student loan</a>
                    {' '}
                    debt is a huge challenge for so many people these days,&quot; says credit and debt expert Wayne Sanford, owner of New Start Financial Corporation.
                    {' '}
                </p>
                <p>&quot;You need to know your options and how you&rsquo;re going to pay the debt off, because you mostly cannot get away from paying your student loans even if you declare bankruptcy."</p>
                <p>That&rsquo;s right—not even bankruptcy will free you from the shackles of Sallie Mae. </p>
                <p>But while we can&rsquo;t get rid of your loans for you, we can give you a comprehensive guide to tackling them yourself and paying off that debt once and for all. </p>
                <p><LazyLoadImage src="/media/what-student-loans-looked-like-for-the-class-of-2016-1.png" alt="What Student Loans Looked Like for the Class of 2016" /></p>
                <h2 id="paying-off-student-loans-smarter-and-faster">Paying off student loans smarter and faster</h2>
                <p>While student loans are considered &quot;good&quot; debt, they&rsquo;re still debt—and can still be a source of stress if you can&rsquo;t afford them. Here are a few ways to reduce your debt as quickly as possible.</p>
                <h3>1. Knock down your interest rate</h3>
                <p>Many lenders offer a 0.25% interest rate discount if you sign up to make your monthly payments via direct deposit. </p>
                <p>This might not sound like much, but over the course of several years it can add up to hundreds of dollars, depending on how much you owe, and all of that extra cash can go toward reducing the balance of your loan. </p>
                <p>Another benefit of signing up for direct deposit is that you&rsquo;re guaranteed to never miss a payment – paying back your student loans can be put on &quot;auto pilot.&quot;</p>
                <h3>2. Make more payments</h3>
                <p>Even if you have a low monthly student loan payment, you can pay off more than you owe each month. </p>
                <p>Federal student loans do not have any pre-payment penalty, so you don&rsquo;t have to pay any extra fees when you choose to pay off your loans faster. </p>
                <p>Many people might be better off by first doing a student loan debt consolidation (to lock in a lower interest rate and minimize the required monthly payments) and then pay more than they owe. </p>
                <p>It&rsquo;s often better to be able to choose to repay more than you owe on a loan, rather than have a required payment every month that might be higher than you can comfortably afford.</p>
                <p>Talk with your lender to see if they offer any unique repayment plans such as biweekly payments (allowing you to repay your student loan with payments every two weeks, thus making 13 full monthly payments per year, instead of the usual 12 payments), or if they offer any other interest rate deductions for things like having a good credit score or having a history of on-time payments.</p>
                <p><LazyLoadImage src="/media/payment-status-of-student-loans-2016-2.png" alt="Payment Status of Student Loans (2016)" /></p>
                <h3>3. Think about loan consolidation</h3>
                <p>If you owe multiple student loans to multiple lenders and student loan programs at a variety of interest rates, it might make sense to consolidate your loans into one monthly payment at one interest rate. </p>
                <p>Student loan debt consolidation can often help you get a lower overall interest rate and a lower monthly payment, especially if you agree to extend the term of your loans (and repay them over more years, but at a lower monthly payment). </p>
                <p>There are a variety of options to reduce your monthly payments, lower your interest rates, or pay off your student loans faster depending on your goals and what you want to commit to.</p>
                <EvenFinancialWidget url="https://embed.hifiona.com/ui/multi-product/index.html?partner=dcslrembed&amp;access_token=a0cf1d51-baba-4676-bb0c-4a76e30f63a0_6f39c4b4-505a-4218-9544-2483627fde13&amp;company_uuid=ca8e88a9-5d45-4570-bc67-c3c0f088d2b3&amp;productType=loan&amp;pw_fiona=true&amp;available_products=loan" slug="student-loans" />
                <h3>4. Consider forgiveness programs</h3>
                <p>Believe it or not, there are some careers that can get your loans forgiven completely if you meet the criteria. </p>
                <p><strong>Public Service Loan Forgiveness</strong></p>
                <p>If you work in a qualifying &quot;public service&quot; career, and your student loan payments are high relative to your income, you might be able to get some of your student loan debt forgiven by the government. </p>
                <p>With the Public Service Loan Forgiveness program, after you have made 120 full monthly payments (10 years&rsquo; worth), and if you follow the rules and guidelines of the program, you can qualify to have your remaining student loan debt forgiven. </p>
                <p>What are some of the types of public service careers that this program applies to? </p>
                <p>
                    Full details are on
                    {' '}
                    <a target="_blank" href="http://studentaid.ed.gov/repay-loans/forgiveness-cancellation/charts/public-service" rel="noreferrer">this website</a>
                    , but the list includes teaching, military service, public safety, law enforcement, public interest law, working for state, local or federal government agencies, or working for a nonprofit organization.&nbsp;
                </p>
                <p><LazyLoadImage src="/media/more-loan-forgiveness-forms-are-being-accepted-3.png" alt="More Loan Forgiveness Forms are Being Accepted" /></p>
                <p><strong>Student Loan Forgiveness for Other Professions</strong></p>
                <p>If you want to career in a health profession, or as a teacher, there are special loan repayment programs and scholarships available for people who want to work in an under-served area. </p>
                <p>For example, many rural areas have a severe shortage of doctors, dentists and nurses, and they have special grants and scholarships available to repay student loans for health professionals who agree to work there. </p>
                <p>There are also loan repayment programs and scholarships for teachers, depending on the state or local school district.</p>
                <p>Here are online directories of student loan forgiveness and scholarship programs for medical professionals and teachers, with state-specific information and websites included:</p>
                <ul className="list">
                    <li>Medical Directory of Loan Repayment/Forgiveness Scholarship Programs</li>
                    <li>Student Loan Forgiveness for Teachers</li>
                </ul>
                <h2 id="if-youre-struggling-to-pay-try-these-tips">If you&rsquo;re struggling to pay, try these tips</h2>
                <p>Sometimes paying off your student debts can be difficult. </p>
                <p>
                    Maybe the monthly payments are
                    {' '}
                    <a href="/debt-management/">too high to manage properly</a>
                    , or maybe you lost a job and can&rsquo;t keep up the payments.
                </p>
                <p>There is hope.</p>
                <h3>1. Check out income-based student loan repayment</h3>
                <p>Some students have a hard time finding a good job after graduation, or they choose to go into a career field that is personally rewarding but not very financially lucrative. </p>
                <p>If you&rsquo;re having trouble paying your student loans, you might want to consider applying for an income-based student loan repayment program. </p>
                <p>
                    The federal government&rsquo;s
                    {' '}
                    <a target="_blank" href="http://studentaid.ed.gov/repay-loans/understand/plans/income-based" rel="noreferrer">Income-Based Repayment (IBR) plan</a>
&nbsp;offers a few advantages:
                </p>
                <ul className="list">
                    <li>Under this repayment plan, your student loan payment is only 15% of your qualifying monthly discretionary income.</li>
                    <li>Your monthly student loan payment will usually be lower than it is under other plans. For example, with an IBR payment, your monthly student loan debt payment is guaranteed to never be more than the amount you would normally pay as part of a 10-year Standard Repayment Plan.</li>
                    <li>Your student loan monthly payment is based on income and family size, and gets adjusted each year based on changes to your annual income and family size – so if your family gets bigger and your income gets smaller, your monthly student loan payment might get smaller (and more affordable) along with your changing finances.</li>
                </ul>
                <p>Not all types of student loans, and not all borrowers, qualify for the IBR plan. </p>
                <p>
                    For more details, and to find out if you qualify and how to apply, check out&nbsp;
                    <a target="_blank" href="http://studentaid.ed.gov/repay-loans/understand/plans/income-based" rel="noreferrer">this website on the federal Income-Based Repayment plan</a>
                    .
                </p>
                <h3>2. Lengthen your repayment period</h3>
                <p>Many people are on a standard repayment plan, where they must repay their loan within 10 years. </p>
                <p>With that plan, you&rsquo;ll pay less overall (because the interest is compounding for a shorter time) than with a longer repayment period, explains Gregory Alerte, a Certified Financial Planner and managing director of Gregory &amp; Company Comprehensive Wealth Management. </p>
                <p>But for some people, the monthly bills that come with a 10-year-repayment schedule are too high. </p>
                <p>If you&rsquo;re in that boat, consider an extended repayment plan, which allows you to repay your loans over a period of up to 25 years. </p>
                <p>&quot;Although extending the repayment plan will, in the long run, cost you more money, it is a decision that has to be made if the standard repayment plan is too much for your budget,&quot; says Sanford. </p>
                <p>To qualify, you must have more than $30,000 in debt.</p>
                <h3>3. Change your repayment method</h3>
                <p>If you can&rsquo;t qualify for an extended repayment plan or want another option, you have a few choices. </p>
                <p>Consider a graduated repayment option, in which you repay your loans in 10 years, but the payments start out low and then increase every two years or so (so you might start out paying $210 per month, but towards the end of the loan period pay more than $500 per month). </p>
                <p>This option works well for someone who is currently in a low-paying job but will get paid more in the near future, experts say. </p>
                <p>However, you risk being unable to make the higher payments down the road.</p>
                <p>A number of other repayment options take income into consideration. </p>
                <p>These include the income-based repayment plan (term is up to 25 years and monthly payments are based on income, family size and state); the pay as you earn repayment plan (term is up to 20 years, and payments are based on income, family size and state); the income-contingent repayment plan (term is up to 25 years and payments are based on income, family size and total amount of loans); and the income-sensitive repayment model (term is up to 10 years and payments are based on income). </p>
                <p>The U.S. Department of Education offers more details on each of these options.</p>
                <p><LazyLoadImage src="/media/a-look-at-repayment-plan-participants-2016-4.png" alt="A Look at Repayment Plan Participants (2016)" /></p>
                <h2 id="what-to-do-if-you-cant-pay-at-all">What to do if you can&rsquo;t pay at all</h2>
                <p>When financial hardship strikes, it can strike hard. </p>
                <p>Sometimes you may be forced to choose what bills get paid and what don&rsquo;t. </p>
                <p>If you find yourself in this situation and are unable to pay your student loans, you have options. </p>
                <p>
                    The National Student Loan Data System (
                    <a target="_blank" href="http://www.nslds.ed.gov/nslds_SA/SaFaq.do" rel="noreferrer">NSLDS</a>
                    ) offers financial aid reviews, loan status reports and many answers to frequently asked questions, but you might also consider choosing between deferment or forbearance.
                </p>
                <h3>Student Loan Deferment</h3>
                <p>Deferments allow for a delay in payments completely or a reduction in payments to cover the interest alone for the duration of the situation. </p>
                <p>Qualifying can be automatic under certain circumstances, which accelerates the deferment approval.</p>
                <h3>Student Loan Forbearance</h3>
                <p>This type of payment delay comes with less stringent requirements than deferment. </p>
                <p>But the lender will most likely require documentation to confirm the situation. There are two types of forbearance: discretionary and mandatory. </p>
                <p>Discretionary is based on the judgment of the lender. With a mandatory forbearance, if the criteria are met, the lender is required to offer it.</p>
                <p><LazyLoadImage src="/media/student-debt-statistics-by-loan-status-2017-5.png" alt="Student Debt Statistics by Loan Status (2017)" /></p>
                <h3>The pros and cons of each</h3>
                <p>Both deferment and forbearance may allow for a complete postponement of payments. </p>
                <p>
                    But the choice made should be based on the particular
                    {' '}
                    <a target="_blank" href="http://www.igrad.com/articles/deferment-versus-forbearance-what-is-the-difference" rel="noreferrer">situation</a>
                    .
                </p>
                <p>Deferments can apply to students returning to school at least half time and come with an automatic approval criteria. </p>
                <p>Forbearance offers neither. </p>
                <p>But the advantage of a deferment is that it does not accrue interest, so the borrower pays nothing more in the long run. </p>
                <p>Forbearance accrues interest, and that adds up to the borrower paying more in the long run.</p>
                <p>If either of these options seems like it might work for you, then you should start the process as soon as possible.</p>
                <p>
                    A former student should first look to the
                    {' '}
                    <a target="_blank" href="http://www.slcsloanhelp.com/list_of_servicers.aspx" rel="noreferrer">loan servicer</a>
&nbsp;directly.
                </p>
                <p>If forbearance is an option, they should be able to provide you with the proper forms you will need. </p>
                <p>
                    If the borrower finds contacting the loan servicer or lender difficult, they can download various
                    {' '}
                    <a target="_blank" href="http://www.igrad.com/articles/download-financial-aid-direct-loans-forms-deferments" rel="noreferrer">deferment forms</a>
&nbsp;based on the rationale of the request.
                </p>
                <p>
                    The borrower may also download
                    {' '}
                    <a target="_blank" href="http://www.igrad.com/articles/download-financial-aid-direct-loans-forms-forbearance" rel="noreferrer">forbearance forms</a>
                    .
                </p>
                <p>Because student loans are overseen by the government, they can offer a wealth of information on student loan information.</p>
                <p>Above all else, borrowers should be sure to continue making loan payments until they have acquired a deferment or forbearance. </p>
                <p>Those who have defaulted on their loans are not eligible for these options. </p>
                <p>Borrowers having problems or anticipating problems making loan payments should contact the loan servicer or lender quickly to discuss available options.</p>
                <h3>Life after loans</h3>
                <p>Even though it may not seem like it at this time, you will one day pay off your student loans.</p>
                <p>And when you do so, you can celebrate. </p>
                <p>You can rest easy knowing that your education has been paid for and that you&rsquo;ve put what you learned to good use—by paying off the cost of that same education.</p>
                <p> Do you have any advice for paying off student loans?</p>
                <p>What methods worked best for you?</p>
                <p>Let us know in the comments below!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default StudentLoansPage;
